import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div>
            <h2 className="text-xl font-bold mb-4">Contact Us</h2>
            <p>123 Main Street</p>
            <p>City, State, Zip Code</p>
            <p>Email: info@diagnosticscenter.com</p>
            <p>Phone: +1234567890</p>
          </div>
          <div>
            <h2 className="text-xl font-bold mb-4">Opening Hours</h2>
            <p>Monday - Friday: 8:00am - 6:00pm</p>
            <p>Saturday: 9:00am - 4:00pm</p>
            <p>Sunday: Closed</p>
          </div>
          <div>
            <h2 className="text-xl font-bold mb-4">Follow Us</h2>
            <div className="flex space-x-4">
              <a href="#" className="text-white hover:text-gray-300">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" className="text-white hover:text-gray-300">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="text-white hover:text-gray-300">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="#" className="text-white hover:text-gray-300">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
        <hr className="my-8 border-gray-700" />
        <div className="text-center">
          <p>&copy; 2024 Diagnostics Center. All rights reserved.</p>
          <p>Designed by Your Company</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
