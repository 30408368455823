import React, { useState } from "react";
import "./Plancard.css";

export default function Plancard({ item }) {
  return (
    <div className="card_main">
      <div className="card_main_details">
        <h2 className="title">{item.testname}</h2>
        <p className="description">{item.testcode}</p>
      </div>
      <div className="buttons_div">
        <div className="price_div">
          <h3 className="price">{"₹" + item.price}</h3>
          <p className="mrp">{"₹" + item.mrp}</p>
        </div>
      </div>
    </div>
  );
}
