import React from "react";
import Logo from "../assets/logo.jpeg";

const Navbar = () => {
  return (
    <nav className="bg-white py-4">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <img className="w-40" src={Logo} />
          <ul style={{ color: "#ec5b0a" }} className="flex space-x-6">
            <li>
              <a href="#" className="hover:text-gray-300">
                Home
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-gray-300">
                Services
              </a>
            </li>
            <li>
              <a href="#" className="hover:text-gray-300">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
