import React from "react";

const ServicesSection = () => {
  return (
    <div className="container mx-auto px-0 py-8">
      <h1 className="text-3xl font-bold mb-8">Our Services</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="bg-white border border-purple-900 p-6 shadow-md rounded-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
          <div className="flex justify-center items-center mb-4">
            <h2 className="text-2xl font-bold text-purple-900">X-Ray</h2>
          </div>
          <p className="text-purple-900">
            We offer X-ray services for bone and joint imaging, chest X-rays,
            and more.
          </p>
        </div>
        <div className="bg-white border border-purple-900 p-6 shadow-md rounded-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
          <div className="flex justify-center items-center mb-4">
            <h2 className="text-2xl font-bold text-purple-900">Ultrasound</h2>
          </div>
          <p className="text-purple-900">
            Our ultrasound services include abdominal ultrasound, obstetric
            ultrasound, and more.
          </p>
        </div>
        <div className="bg-white border border-purple-900 p-6 shadow-md rounded-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
          <div className="flex justify-center items-center mb-4">
            <h2 className="text-2xl font-bold text-purple-900">Blood Tests</h2>
          </div>
          <p className="text-purple-900">
            We provide various blood tests including CBC, lipid profile, blood
            sugar tests, and more.
          </p>
        </div>
        <div className="bg-white border border-purple-900 p-6 shadow-md rounded-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
          <div className="flex justify-center items-center mb-4">
            <h2 className="text-2xl font-bold text-purple-900">ECG</h2>
          </div>
          <p className="text-purple-900">
            We offer electrocardiogram (ECG) services to monitor heart activity
            and detect abnormalities.
          </p>
        </div>
        <div className="bg-white border border-purple-900 p-6 shadow-md rounded-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
          <div className="flex justify-center items-center mb-4">
            <h2 className="text-2xl font-bold text-purple-900">CT Scan</h2>
          </div>
          <p className="text-purple-900">
            Our CT scan services help in diagnosing conditions like injuries,
            tumors, infections, and more.
          </p>
        </div>
        <div className="bg-white border border-purple-900 p-6 shadow-md rounded-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
          <div className="flex justify-center items-center mb-4">
            <h2 className="text-2xl font-bold text-purple-900">
              MRI (Coming Soon)
            </h2>
          </div>
          <p className="text-purple-900">
            Stay tuned! We are working on bringing MRI services to our
            diagnostic center.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
