import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image from "../assets/1076291-test.jpg";

const ImageCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };

  return (
    <Slider {...settings}>
      <div>
        <img src={Image} alt="Image 1" className="w-full h-96 object-cover" />
      </div>
      <div>
        <img src={Image} alt="Image 2" className="w-full h-96 object-cover" />
      </div>
      <div>
        <img src={Image} alt="Image 3" className="w-full h-96 object-cover" />
      </div>
    </Slider>
  );
};

export default ImageCarousel;
