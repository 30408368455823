import React, { useState } from "react";
import "./Contact.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";

export default function Contact() {
  return (
    <div className="contact_main">
      <div className="contact_heading">
        <h2 className="contact_heading_h2">Get in touch with us...</h2>
        {/* <hr /> */}
      </div>
      <div className="contact-content">
        <div className="div_address">
          <h2>Address:</h2>
          <div className="contact_address_div">
            <div className="icon">
              <LocationOnIcon />
            </div>
            <div className="contact_address_details">
              <a>Wens Diagnostics Pvt Ltd</a>
              <a>Pargi Road, Shadnagar, Telangana 509216</a>
            </div>
          </div>
          <div className="contact_address_div">
            <div className="icon">
              <PhoneIcon />
            </div>
            <div className="contact_address_details">
              <a href="tel:9999999999">+91 9999999999</a>
              <a href="tel:9999999999">+91 9999999999</a>
            </div>
          </div>
          <div className="contact_address_div">
            <div className="icon">
              <WhatsAppIcon />
            </div>
            <div className="contact_address_details">
              <a
                onClick={() =>
                  (window.location.href =
                    "//api.whatsapp.com/send?phone=919999999999")
                }
              >
                +91 9999999999
              </a>
              <a
                onClick={() =>
                  (window.location.href =
                    "//api.whatsapp.com/send?phone=919999999999")
                }
              >
                +91 9999999999
              </a>
            </div>
          </div>
          <div className="contact_address_div">
            <div className="icon">
              <EmailIcon />
            </div>
            <div className="contact_address_details">
              <a href="mailto:wensdiagnostics@gmail.com">
                wensdiagnostics@gmail.com
              </a>
            </div>
          </div>
        </div>
        <div className="div_map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15256.31717955653!2d78.2009662!3d17.0687736!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcbcfb690bc09c3%3A0x354faee131ec9693!2sWens%20diagnostics%20pvt%20ltd!5e0!3m2!1sen!2sin!4v1714318689311!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{
              minWidth: "15rem",
              minHeight: "15rem",
              borderRadius: "7px",
            }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
