import React, { useEffect } from "react";
import "./Test.css";
import Plancard from "../plan/Plancard";
import { useState } from "react";
import testData from "../../assets/testData.json";

export default function Tests({ limit }) {
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTxt, setSearchTxt] = useState("");
  const [showAll, setShowAll] = useState(true);

  useEffect(() => {
    applyFilters();
  }, [searchTxt]);

  const applyFilters = (
    testPackageFilter,
    visitFilter,
    specializationFilter
  ) => {
    setLoading(true);
    setFilteredData(
      testData?.filter((data) => {
        if (data.testname?.toLowerCase().includes(searchTxt?.toLowerCase())) {
          return data;
        }
      })
    );
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  return (
    <div className="test">
      <div className="tests_heading">
        <h2>Tests/Packages</h2>
      </div>
      <div className="test_main">
        <div className="test_container">
          <input
            type="text"
            className="search_input"
            placeholder="Tests/Packages"
            value={searchTxt}
            onChange={(text) => {
              setSearchTxt(text.target.value);
            }}
          />
          {loading ? (
            <div>
              <p>Loading</p>
            </div>
          ) : (
            <div className="tests_list">
              {filteredData
                .slice(0, showAll ? 8 : undefined)
                .map((item, index) => {
                  return <Plancard key={index} item={item} />;
                })}
            </div>
          )}
          <button
            className="bg-blue-700 p-4 rounded-lg text-white mt-4 mb-4"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "Show More" : "Hide Tests"}
          </button>
        </div>
      </div>
    </div>
  );
}
