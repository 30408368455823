import React from "react";
import Navbar from "./components/Navbar";
import ImageCarousel from "./components/ImageCarousel";
import ServicesSection from "./components/ServicesSection";
import ContactUsSection from "./components/ContactUsSection";
import Footer from "./components/Footer";
import Tests from "./components/test/Tests";
import Contact from "./components/contact/Contact";

const LandingPage = () => {
  return (
    <div>
      <Navbar />
      <ImageCarousel />
      <ServicesSection />
      <Tests />
      <Contact />
      {/* <ContactUsSection /> */}
      <Footer />
    </div>
  );
};

export default LandingPage;
